body {
  margin: 0;
  font-family: Roboto Slab, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 17px;
}

.mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-top-left {
  z-index: 0;
}

[data-reach-dialog-overlay] {
  z-index: 2;
}

.mapbox-improve-map {
  display: none;
}

.mapboxgl-ctrl-attrib {
  opacity: .5;
}